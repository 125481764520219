import {AuthBindings} from "@refinedev/core";

import {api} from "./utils";

export const TOKEN_KEY = "auth";
export const USER_INFO_KEY = 'user-info'

export const authProvider: AuthBindings = {
  login: async ({email, password}) => {
    try {
      const {data: {data}} = await api.post('/login', {email, password})
      const {access_token, user} = data
      localStorage.setItem(TOKEN_KEY, access_token);
      localStorage.setItem(USER_INFO_KEY, JSON.stringify(user))

      return {
        success: true,
        redirectTo: "/",
      };
    } catch (error) {
      return {success: false}
    }
  },
  logout: async () => {
    await api.post('/logout')
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_INFO_KEY);

    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      return {authenticated: false};
    }

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    try {
      const {data} = await api.get('/me')
      localStorage.setItem(USER_INFO_KEY, JSON.stringify(data.data))
      return {authenticated: true}
    } catch (error) {
      return {authenticated: false, redirectTo: "/login"};
    }
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const user = localStorage.getItem(USER_INFO_KEY);

    if (user) {
      return JSON.parse(user)
    }

    return null;
  },
  onError: async (error) => {
    return {error};
    // return {
    //   logout: true,
    //   redirectTo: "/login",
    //   error,
    // };

  },
};
