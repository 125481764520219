import React from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate, useNotification, BaseKey,
} from '@refinedev/core';
import {
  List, RefreshButton,
  ShowButton, useTable,
} from '@refinedev/antd';
import {Table, Space, Button, Typography, Tooltip, Tag, Spin, Popconfirm} from 'antd';
import {
  BugOutlined,
  CheckCircleOutlined,
  DeleteOutlined, FilePdfOutlined,
  LoadingOutlined,
  PauseCircleOutlined
} from "@ant-design/icons";
import {api} from "../../utils";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {add} from "lodash";

export const DossierList: React.FC<IResourceComponentsProps> = () => {
  const {tableProps, tableQueryResult} = useTable({
    syncWithLocation: true,
    resource: 'product.dossier',
    queryOptions: {
      retry: 0,
      retryDelay: 1,
    }
  });

  const {open} = useNotification();
  const refreshTable = () => tableQueryResult.refetch()
  const handleRunValidation = async (id: BaseKey | undefined) => {
    try {
      await api.post(`/dossier/${id}/run-validation`)
      open?.({
        type: "success",
        message: "success",
        description: "Validation started",
      });
      refreshTable()
    } catch (e) {
      open?.({
        type: "error",
        message: "error",
        description: "Unexpected error",
      });
    }
  }

  const handleDownloadReport = async (id: BaseKey | undefined) => {
    try {
      open?.({
        type: "success",
        message: t('messages.download_start'),
      });
      const {data} = await api.get(`/dossier/${id}/pdf-report`, {
        responseType: 'blob'
      })

      const url = window.URL.createObjectURL(
          new Blob([data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
          'download',
          `Report.pdf`,
      );
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    } catch (e) {
      open?.({
        type: "error",
        message: "error",
        description: "Unexpected error",
      });
    }
  }

  const toggleActive = async (id: BaseKey | undefined) => {
    try {
      await api.patch(`/dossier/${id}/toggle-active`)
      refreshTable()
    } catch (e) {
      open?.({
        type: "error",
        message: "error",
        description: "Unexpected error",
      });
    }
  }

  const destroy = async (id: BaseKey | undefined) => {
    try {
      await api.delete(`/dossier/${id}`)
      refreshTable()
    } catch (e) {
      open?.({
        type: "error",
        message: "error",
        description: "Unexpected error",
      });
    }
  }

  //TODO add archive button /active/inactive
  const t = useTranslate();

  return (
      <List
          headerButtons={[
            <RefreshButton key={'fresh'} onClick={refreshTable}/>
          ]}
          title={t('labels.dossier_list')}
      >
        <Table
            {...tableProps}
            pagination={false}
            loading={tableQueryResult.isFetching}
            rowKey="id"
        >
          <Table.Column dataIndex="name" title={t('labels.name')}/>
          <Table.Column align="center" dataIndex="sequence" title={t('labels.sequence')}/>
          <Table.Column
              dataIndex="status"
              align="center"
              title={t('labels.status')}
              render={(_, record: BaseRecord) => {
                const trans = t(`labels.${record.status}`);

                if (record.status === 'validation_running') {
                  return <>
                    <Spin indicator={
                      <LoadingOutlined
                          style={{
                            fontSize: 20,
                          }}
                          spin
                      />
                    }/>
                  </>
                }

                if (record.status === 'validation_failed') {
                  return <Tag color={'red'}>{trans}</Tag>
                }

                if (record.status === 'validation_success') {
                  return <Tag color={'success'}>{trans}</Tag>
                }

                return trans
              }}
          />
          <Table.Column dataIndex={['file', 'name']} title={t('labels.file')}/>
          <Table.Column
              width={1}
              dataIndex={['file', 'size']}
              title={t('labels.size')}
              render={(_, record: BaseRecord) => (
                  <Typography.Text type="secondary">
                    {(record?.file?.size / 1024 / 1024).toFixed(2)} mb
                  </Typography.Text>
              )}
          />
          <Table.Column width={1} dataIndex="created_at" title={t('labels.created_at')}/>
          <Table.Column
              width={1}
              render={(_, record: BaseRecord) => (
                  <Space>
                    <Tooltip title={record.active ? t('buttons.deactivate') : t('buttons.activate')}>
                      <Button
                          type={'primary'}
                          ghost
                          danger={record.active}
                          icon={record.active ? <PauseCircleOutlined/> : <CheckCircleOutlined/>}
                          onClick={() => toggleActive(record.id)}
                      />
                    </Tooltip>
                    <Tooltip title={t('buttons.run_validation')}>
                      <Button
                          disabled={!['validation_success', 'validation_failed', 'draft'].includes(record.status)}
                          onClick={() => handleRunValidation(record.id)} icon={<BugOutlined/>}
                      />
                    </Tooltip>
                    <Tooltip title={t('buttons.preview')}>
                      <ShowButton
                          resource={'product.dossier'}
                          recordItemId={record.id}
                          hideText
                      />
                    </Tooltip>
                    {record.status !== 'draft' && <Tooltip title={t('buttons.download_report')}>
                      <Button
                        onClick={() => handleDownloadReport(record.id)}
                        icon={<FilePdfOutlined/>}
                      />
                    </Tooltip>}
                    <Popconfirm
                        title={t('labels.confirm_delete')}
                        onConfirm={() => destroy(record.id)}
                        okText={t('buttons.yes')}
                        cancelText={t('buttons.no')}
                    >
                      <Button
                          danger
                          icon={<DeleteOutlined/>}
                      />
                    </Popconfirm>
                  </Space>
              )}
          />
        </Table>
      </List>
  );
};
