import React from 'react';
import { RefineThemedLayoutV2SiderProps } from '@refinedev/antd/src/components/themedLayoutV2/types';
import {
    CanAccess,
    ITreeMenu,
    pickNotDeprecated,
    TitleProps,
    useActiveAuthProvider,
    useIsExistAuthentication,
    useLink,
    useLogout,
    useMenu,
    useRefineContext,
    useRouterContext,
    useRouterType,
    useTitle,
    useTranslate,
    useWarnAboutChange,
} from '@refinedev/core';
import { Button, Drawer, Grid, Layout, Menu, theme } from 'antd';
import {
    BarsOutlined,
    DashboardOutlined,
    LeftOutlined,
    LogoutOutlined,
    RightOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';

import { ThemedTitleV2, useSiderVisible } from '@refinedev/antd';
import SubMenu from 'antd/es/menu/SubMenu';

const { useToken } = theme;

export const drawerButtonStyles = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    position: 'fixed',
    top: 64,
    zIndex: 999,
};

export const CustomSider: React.FC<RefineThemedLayoutV2SiderProps> = ({
    Title: TitleFromProps,
    render,
    meta,
}) => {
    // @ts-ignore
    const { token } = useToken();
    const {
        siderVisible,
        setSiderVisible,
        drawerSiderVisible,
        setDrawerSiderVisible,
    } = useSiderVisible();

    const isExistAuthentication = useIsExistAuthentication();
    const routerType = useRouterType();
    const NewLink = useLink();
    const { warnWhen, setWarnWhen } = useWarnAboutChange();
    const { Link: LegacyLink } = useRouterContext();
    const Link = routerType === 'legacy' ? LegacyLink : NewLink;
    const TitleFromContext = useTitle();
    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu({ meta });
    const breakpoint = Grid.useBreakpoint();
    const { hasDashboard } = useRefineContext();
    const authProvider = useActiveAuthProvider();
    const { mutate: mutateLogout } = useLogout({
        v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });

    const isMobile =
        typeof breakpoint.lg === 'undefined' ? false : !breakpoint.lg;

    const CustomLogo = () => <div>Custom Logo</div>;

    const CustomSideTitle = (
        props: JSX.IntrinsicAttributes &
            TitleProps & {
                icon?: React.ReactNode;
                text?: React.ReactNode;
                wrapperStyles?: React.CSSProperties | undefined;
            }
    ) => <ThemedTitleV2 {...props} text="eCTD Редактор" />;

    const RenderToTitle = TitleFromProps ?? TitleFromContext ?? CustomSideTitle;

    const renderTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
        return tree.map((item: ITreeMenu) => {
            const {
                icon,
                label,
                route,
                key,
                name,
                children,
                parentName,
                meta,
                options,
            } = item;

            if (children.length > 0) {
                return (
                    <CanAccess
                        key={item.key}
                        resource={name.toLowerCase()}
                        action="list"
                        params={{
                            resource: item,
                        }}
                    >
                        <SubMenu
                            key={item.key}
                            icon={icon ?? <UnorderedListOutlined />}
                            title={label}
                        >
                            {renderTreeView(children, selectedKey)}
                        </SubMenu>
                    </CanAccess>
                );
            }
            const isSelected = key === selectedKey;
            const isRoute = !(
                pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
                    undefined && children.length === 0
            );

            return (
                <CanAccess
                    key={item.key}
                    resource={name.toLowerCase()}
                    action="list"
                    params={{
                        resource: item,
                    }}
                >
                    <Menu.Item
                        key={item.key}
                        icon={icon ?? (isRoute && <UnorderedListOutlined />)}
                    >
                        <Link to={route ?? ''}>{label}</Link>
                        {!drawerSiderVisible && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </Menu.Item>
                </CanAccess>
            );
        });
    };

    const handleLogout = () => {
        if (warnWhen) {
            const confirm = window.confirm(
                translate(
                    'warnWhenUnsavedChanges',
                    'Are you sure you want to leave? You have unsaved changes.'
                )
            );

            if (confirm) {
                setWarnWhen(false);
                mutateLogout();
            }
        } else {
            mutateLogout();
        }
    };

    const logout = isExistAuthentication && (
        <Menu.Item
            key="logout"
            onClick={() => handleLogout()}
            icon={<LogoutOutlined />}
        >
            {translate('buttons.logout', 'Logout')}
        </Menu.Item>
    );

    const dashboard = hasDashboard ? (
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/">{translate('dashboard.title', 'Dashboard')}</Link>
            {!drawerSiderVisible && selectedKey === '/' && (
                <div className="ant-menu-tree-arrow" />
            )}
        </Menu.Item>
    ) : null;

    const items = renderTreeView(menuItems, selectedKey);

    const renderSider = () => {
        if (render) {
            return render({
                dashboard,
                items,
                logout,
                collapsed: drawerSiderVisible,
            });
        }
        return (
            <>
                {dashboard}
                {items}
                {logout}
            </>
        );
    };

    const renderMenu = () => {
        return (
            <>
                <Menu
                    selectedKeys={selectedKey ? [selectedKey] : []}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    style={{
                        marginTop: '8px',
                        border: 'none',
                    }}
                    onClick={() => {
                        setSiderVisible?.(false);
                        if (!breakpoint.lg) {
                            setDrawerSiderVisible?.(true);
                        }
                    }}
                >
                    {renderSider()}
                </Menu>
            </>
        );
    };

    const renderDrawerSider = () => {
        return (
            <>
                <Drawer
                    open={siderVisible}
                    onClose={() => setSiderVisible?.(false)}
                    placement="left"
                    closable={false}
                    width={200}
                    bodyStyle={{
                        padding: 0,
                    }}
                    maskClosable={true}
                >
                    <Layout>
                        <Layout.Sider
                            style={{
                                height: '100vh',
                                overflow: 'hidden',
                                backgroundColor: token.colorBgContainer,
                                borderRight: `1px solid ${token.colorBgElevated}`,
                            }}
                        >
                            <div
                                style={{
                                    width: '200px',
                                    padding: '0 16px',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '64px',
                                    backgroundColor: token.colorBgElevated,
                                }}
                            >
                                <RenderToTitle collapsed={false} />
                            </div>
                            {renderMenu()}
                        </Layout.Sider>
                    </Layout>
                </Drawer>
                <Button
                    // @ts-ignore
                    style={drawerButtonStyles}
                    size="large"
                    onClick={() => setSiderVisible?.(true)}
                    icon={<BarsOutlined />}
                ></Button>
            </>
        );
    };

    if (isMobile) {
        return renderDrawerSider();
    }

    return (
        <Layout.Sider
            style={{
                backgroundColor: token.colorBgContainer,
                borderRight: `1px solid ${token.colorBgElevated}`,
            }}
            collapsible
            collapsed={drawerSiderVisible}
            onCollapse={(collapsed, type) => {
                if (type === 'clickTrigger') {
                    setDrawerSiderVisible?.(collapsed);
                }
            }}
            collapsedWidth={80}
            breakpoint="lg"
            trigger={
                <Button
                    type="text"
                    style={{
                        borderRadius: 0,
                        height: '100%',
                        width: '100%',
                        backgroundColor: token.colorBgElevated,
                    }}
                >
                    {drawerSiderVisible ? (
                        <RightOutlined
                            style={{
                                color: token.colorPrimary,
                            }}
                        />
                    ) : (
                        <LeftOutlined
                            style={{
                                color: token.colorPrimary,
                            }}
                        />
                    )}
                </Button>
            }
        >
            <div
                style={{
                    width: drawerSiderVisible ? '80px' : '200px',
                    padding: drawerSiderVisible ? '0' : '0 16px',
                    display: 'flex',
                    justifyContent: drawerSiderVisible
                        ? 'center'
                        : 'flex-start',
                    alignItems: 'center',
                    height: '64px',
                    backgroundColor: token.colorBgElevated,
                    fontSize: '14px',
                }}
            >
                <RenderToTitle collapsed={drawerSiderVisible} />
            </div>
            {renderMenu()}
        </Layout.Sider>
    );
};

export default CustomSider;
