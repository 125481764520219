import React from "react";
import {IResourceComponentsProps, BaseRecord, useTranslate} from "@refinedev/core";
import {
    useTable,
    List,
    ShowButton,
    TagField,
    EmailField,
    DateField,
} from "@refinedev/antd";
import {Table, Space} from "antd";

export const EmployeeList: React.FC<
    IResourceComponentsProps
> = () => {
    const {tableProps} = useTable({
        syncWithLocation: true,
    });

    const t = useTranslate();

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="name"
                    title={t("labels.full_name")}
                />
                <Table.Column
                    dataIndex={["email"]}
                    title={t("labels.email")}
                    render={(value: any) => <EmailField value={value}/>}
                />
                <Table.Column
                    dataIndex={["company", "name"]}
                    title={t("labels.company")}
                />
                <Table.Column
                    dataIndex="created_at"
                    title={t("labels.created_at")}
                />
            </Table>
        </List>
    );
};
