import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { endpoint } from './config';

i18n.use(Backend)
    .use(detector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'uk'],
        backend: {
            loadPath: `${endpoint}/lang/{{lng}}`,
        },
        defaultNS: 'common',
        fallbackLng: ['en', 'uk'],
    });

export default i18n;
