import React from 'react';
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from '@refinedev/core';
import ReactJson from 'react-json-view';
import {
    Show,
    TagField,
    TextField,
    UrlField,
    DateField,
    Breadcrumb,
} from '@refinedev/antd';
import { Button, Typography } from 'antd';
import { CustomBreadcrumbs } from '../../components/CustomBreadcrumbs';

const { Title } = Typography;

export const ActivityShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow({
        queryOptions: {
            retry: 0,
            retryDelay: 1,
        },
    });
    const { data, isLoading, refetch } = queryResult;

    const record = data?.data;

    const t = useTranslate();

    return (
        <Show
            isLoading={isLoading}
            title={t('labels.show')}
            breadcrumb={<CustomBreadcrumbs />}
        >
            <Title level={5}>ID</Title>
            <TextField value={record?.id} />
            <Title level={5}>Log Name</Title>
            <TextField value={record?.log_name} />
            {record?.causer && (
                <>
                    <Title level={5}>Causer</Title>
                    <ReactJson src={record?.causer} name={false} />
                </>
            )}

            <Title level={5}>Properties</Title>
            <ReactJson src={record?.properties || {}} name={false} />
            <Title level={5}>Description</Title>
            <TextField value={record?.description} />
            <Title level={5}>Created At</Title>
            <TextField value={record?.created_at} />
        </Show>
    );
};
