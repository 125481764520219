import { AuthPage } from '@refinedev/antd';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    Layout,
    Row,
    theme,
    Typography,
} from 'antd';
import { bodyStyles, containerStyles, headStyles, titleStyles } from './styles';
import { layoutStyles } from './layoutStyles';
import { LoginFormTypes, useLogin, useTranslate } from '@refinedev/core';
import React from 'react';

const { Title } = Typography;
const { useToken } = theme;

export const Login = () => {
    const { token } = useToken();
    const [form] = Form.useForm<LoginFormTypes>();
    const translate = useTranslate();
    const { mutate: login, isLoading } = useLogin<LoginFormTypes>();
    const formProps = {};

    const CardTitle = (
        <Title
            level={3}
            style={{
                color: token.colorPrimaryTextHover,
                ...titleStyles,
            }}
        >
            {translate(
                'labels.sign_in_to_your_account',
                'Sign in to your account'
            )}
        </Title>
    );

    const CardContent = (
        <Card
            title={CardTitle}
            headStyle={headStyles}
            bodyStyle={bodyStyles}
            style={{
                ...containerStyles,
                backgroundColor: token.colorBgElevated,
            }}
        >
            <Form<LoginFormTypes>
                layout="vertical"
                form={form}
                onFinish={values => login(values)}
                requiredMark={false}
                initialValues={{
                    email: 'test@mail.com',
                    password: 'secret',
                }}
                {...formProps}
            >
                <Form.Item
                    name="email"
                    label={translate('labels.email', 'Email')}
                    rules={[
                        { required: true },
                        {
                            type: 'email',
                            message: translate(
                                'labels.validEmail',
                                'Invalid email address'
                            ),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        placeholder={translate('labels.email', 'Email')}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={translate('labels.password', 'Password')}
                    rules={[{ required: true }]}
                >
                    <Input
                        type="password"
                        placeholder="●●●●●●●●"
                        size="large"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={isLoading}
                        block
                    >
                        {translate('buttons.signin', 'Sign in')}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );

    return (
        <Layout style={layoutStyles}>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: '100vh',
                }}
            >
                <Col xs={22}>{CardContent}</Col>
            </Row>
        </Layout>
    );
};
