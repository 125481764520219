import React, { useState } from 'react';
import { IResourceComponentsProps, useTranslate } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form } from 'antd';
import { FileUploader, Success } from '../../components/FileUploader';
import { CustomBreadcrumbs } from '../../components/CustomBreadcrumbs';

export const UploaderCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, form } = useForm<any>({
        onMutationSuccess: ({ data }, variables, context) => {
            let payload = data?.data;
            let appId: string = payload?.id;

            console.log('appId', appId);
        },
        onMutationError: () => {},
        redirect: false,
    });
    const [fileReady, setFileReady] = useState(false);

    const handleSuccess = (fileId: Success) => {
        form?.setFieldsValue({
            file_id: fileId ?? null,
        });
        setFileReady(!!fileId);
    };
    const t = useTranslate();

    return (
        <Create
            headerProps={{
                title: t('labels.file_uploader'),
                backIcon: null,
            }}
            footerButtons={({ defaultButtons }) => <></>}
            breadcrumb={<CustomBreadcrumbs />}
        >
            <Form
                layout="vertical"
                initialValues={{
                    file_id: null,
                }}
                {...formProps}
            >
                <Form.Item name="file_id">
                    <FileUploader
                        onSuccess={handleSuccess}
                        onRemove={() => console.log('file removed!')}
                        accept=".zip"
                    />
                </Form.Item>
            </Form>
        </Create>
    );
};
