import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useGo,
  useNavigation,
  useGetToPath,
  useLink
} from "@refinedev/core";
import {useTable, List, ShowButton, CreateButton, ListButton} from "@refinedev/antd";
import {Table, Space, Button} from "antd";
import {IHeaderButton} from "../../interfaces/dossier";
import { UnorderedListOutlined } from "@ant-design/icons";

export const ProductList: React.FC<IResourceComponentsProps> = () => {
  const {tableProps} = useTable({
    syncWithLocation: true,
  });
  const t = useTranslate();
  const Link = useLink();

  return (
      <List
          headerButtons={({defaultButtons}) => {
            let btn = defaultButtons as IHeaderButton;
            let innerPropsBtn = btn?.props?.children?.props;

            return (
                <CreateButton
                    {...innerPropsBtn}
                    resource={'dossier'}
                >
                  {t('buttons.import_dossier')}
                </CreateButton>
            );
          }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
              dataIndex="name"
              title={t('labels.goods_name')}
              render={(_, record: BaseRecord) => (
                  <>
                    <p>{record.name}</p>
                    <sup>{record.identifier}</sup>
                  </>
              )}
          />
          <Table.Column dataIndex="created_by" title={t('labels.created_by')}/>
          <Table.Column dataIndex="created_at" title={t('labels.created_at')}/>
          <Table.Column dataIndex="dossier_count" title={t('labels.dossier_count')}/>
          <Table.Column
              width={1}
              dataIndex="actions"
              render={(_, record: BaseRecord) => (
                  <Space>
                    <Link to={'/product/' + record.id + '/dossier'}>
                      <Button icon={<UnorderedListOutlined />} />
                    </Link>
                  </Space>
              )}
          />
        </Table>
      </List>
  );
};
