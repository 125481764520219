import React, {BaseSyntheticEvent, useState} from 'react';
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from '@refinedev/core';


import Structure from './structure';
import {RefreshButton, Show, TextField} from '@refinedev/antd';
import {
  Typography,
  Space,
  Tree,
  Tag,
  Input,
  Collapse,
  Button,
  Table, Tabs
} from 'antd';
import {CustomBreadcrumbs} from '../../components/CustomBreadcrumbs';
import {api} from "../../utils";
import {FileOutlined, FolderOpenOutlined} from '@ant-design/icons';
import ValidationTable from "./validationTable";
import {useParams} from "react-router-dom";

const {Compact} = Space
const {Title} = Typography;
const {Search} = Input;
const {Panel} = Collapse;

export const DossierShow: React.FC<IResourceComponentsProps> = () => {
  const {id} = useParams();

  const {queryResult} = useShow({
    id,
    resource: 'product.dossier',
    queryOptions: {
      retry: 0,
      retryDelay: 1,
    },
  });
  const {data, isLoading, refetch} = queryResult;
  const record = data?.data;

  const t = useTranslate();

  return (
      <Show
          headerButtons={[
            <RefreshButton key={'refresh'} />
          ]}
          isLoading={isLoading}
          title={t('labels.dossier_preview')}
          breadcrumb={false}
      >
        <Tabs
            items={[
              {
                label: t('labels.main_info'),
                key: 'information',
                children: <>
                  <Title level={5}>{t('labels.name')}</Title>
                  <TextField value={record?.name}/>

                  <Title level={5}>Sequence</Title>
                  <TextField value={record?.sequence}/>

                  <Title level={5}>{t('labels.created_at')}</Title>
                  <TextField value={record?.created_at}/>

                  <Title level={5}>Envelope {record?.sequence}</Title>
                  <Collapse defaultActiveKey={1}>
                    <Panel header="Envelope" key="1">
                      <pre>{record?.envelope}</pre>
                    </Panel>
                  </Collapse>
                </>
              },
              {
                label: t('labels.structure'),
                key: 'structure',
                children: <Structure record={record}/>,
              },
              {
                label: t('labels.validation_result'),
                key: 'validation',
                children: <ValidationTable data={record?.validation}/>,
                disabled: record?.validation?.length === 0
              }
            ]}
        >
        </Tabs>
      </Show>
  );
};
