import React, {useState} from 'react';
import {IResourceComponentsProps, useTranslate} from '@refinedev/core';
import {Create, SaveButton, useForm} from '@refinedev/antd';

import {Form, Input} from 'antd';
import {FileUploader, Success} from '../../components/FileUploader';
import {CustomBreadcrumbs} from '../../components/CustomBreadcrumbs';
import {IDossier, IFooterButton} from '../../interfaces/dossier';

export const DossierCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const {
        formProps,
        saveButtonProps,
        form: formInstance,
    } = useForm<IDossier>({
        onMutationError: () => {
        },
        redirect: false,
        successNotification: {
            message: t('notifications.the_dossier_was_successfully_created'),
            type: 'success',
        },
    });
    const [fileReady, setFileReady] = useState(false);

    const handleSuccess = (fileId: Success) => {
        formInstance?.setFieldsValue({
            tus_file_id: fileId ?? null,
        });
        setFileReady(!!fileId);
    };

    const handleRemove = () => {
    };

    return (
        <Create
            saveButtonProps={{
                ...saveButtonProps,
                disabled: !fileReady,
            }}
            headerProps={{
                title: t('labels.dossier_import'),
            }}
            breadcrumb={false}
            footerButtons={({defaultButtons}) => {
                let btn = defaultButtons as IFooterButton;
                let innerPropsBtn = btn?.props?.children?.props;

                return (
                    <SaveButton {...innerPropsBtn}>
                        {t('buttons.save')}
                    </SaveButton>
                );
            }}
        >
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label={t('labels.name')}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={t('labels.file')}
                    name="tus_file_id"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <FileUploader
                        onSuccess={handleSuccess}
                        onRemove={handleRemove}
                        accept=".zip"
                    ></FileUploader>
                </Form.Item>
            </Form>
        </Create>
    );
};
