import React from "react";
import {IResourceComponentsProps, useTranslate} from "@refinedev/core";
import {Create, useForm} from "@refinedev/antd";
import {Form, Input} from "antd";
import {CustomBreadcrumbs} from "../../components/CustomBreadcrumbs";

export const EmployeeCreate: React.FC<
	IResourceComponentsProps
> = () => {
	const t = useTranslate()
	const {
		form,
		formProps,
		saveButtonProps,
	} = useForm({
		onMutationError: ({response: {data: {errors}}}) => {
			form.setFields(
				Object.keys(errors).map((key: string): any => ({
						name: key
							.split('.')
							.map(i => (Number.isInteger(+i) ? +i : i)),
						errors: errors[key]
					})
				)
			)
		}
	});

	return (
		<Create
			title={t('labels.creating_employee')}
			saveButtonProps={{...saveButtonProps, children: t('buttons.create')}}
			breadcrumb={<CustomBreadcrumbs/>}
		>
			<Form {...formProps} layout="vertical">
				<Form.Item
					label={t('labels.full_name')}
					name={["name"]}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input/>
				</Form.Item>
				<Form.Item
					label={t('labels.email')}
					name={["email"]}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input/>
				</Form.Item>
				<Form.Item
					label={t('labels.password')}
					name="password"
					rules={[{required: true}]}
				>
					<Input type="password"/>
				</Form.Item>
				<Form.Item
					label={t('labels.password_confirmation')}
					name="password_confirmation"
					rules={[{required: true}]}
				>
					<Input type="password"/>
				</Form.Item>
			</Form>
		</Create>
	);
};
