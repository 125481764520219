import { Button, Progress, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTusStore } from 'use-tus';
import { getAuthKey } from '../../utils/auth';
import { v4 as uuidv4 } from 'uuid';
import { endpoint } from '../../config';
import { useTranslate } from '@refinedev/core';

import styles from './FileUploader.module.scss';

// import { useTranslate } from '@pankod/refine-core';
const cacheKey = uuidv4();

export type Success = string | null;

interface FileUploaderProps {
    onSuccess: (id: Success) => void;
    onRemove?: () => void;
    accept?: string;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
    onSuccess,
    onRemove,
    accept,
}) => {
    const [progress, setProgress] = useState(0);
    const { setUpload, upload, isSuccess, isAborted, remove } = useTusStore(
        cacheKey,
        {
            autoAbort: true,
            uploadOptions: {
                uploadUrl: null,
            },
        }
    );
    const [fileList, setFileList] = useState<any[] | []>([]);
    const token = getAuthKey();
    const t = useTranslate();

    useEffect(() => {
        if (isAborted) {
            remove();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAborted]);

    useEffect(() => {
        if (uploadedUrl) {
            let fileUuid = uploadedUrl.split('/').slice(-1)?.[0];

            if (fileUuid) {
                onSuccess(fileUuid);
            }
        } else {
            onSuccess(null);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const uploadedUrl = useMemo(
        () => isSuccess && upload?.url,
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [upload, isSuccess, isAborted]
    );

    const handleSetUpload = useCallback(
        async (event: { fileList: any }) => {
            const fileLastItem = [...event.fileList].pop();
            const file = fileLastItem?.originFileObj;

            setFileList([
                {
                    ...fileLastItem,
                },
            ]);

            if (!file) {
                return;
            }

            setUpload(file, {
                endpoint: `${endpoint}/upload/`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                //25mb in bytes
                chunkSize: 25 * 1024 * 1024,
                metadata: file
                    ? {
                          filename: `${cacheKey}_${file.name}`,
                          filetype: file.type,
                      }
                    : undefined,
                onProgress: (bytesSent: number, bytesTotal: number) => {
                    setProgress(
                        Number(((bytesSent / bytesTotal) * 100).toFixed(2))
                    );
                },
                removeFingerprintOnSuccess: true,
            });
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [setUpload]
    );

    const handleStart = useCallback(async () => {
        if (!upload) {
            return;
        }

        const previousUploads = await upload.findPreviousUploads();

        if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        upload.start();
    }, [upload]);

    const handleFileReset = () => {
        remove();
        setFileList([]);
        setProgress(0);
    };

    const uploadProps: UploadProps = {
        onChange: handleSetUpload,
        beforeUpload: () => false,
        multiple: false,
        className: styles.uploadButton,
        fileList,
        onRemove: () => {
            onRemove!();
            handleFileReset();
        },
        accept,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.line}>
                <Upload {...uploadProps}>
                    <Button htmlType="button">
                        {t('labels.select_a_file')}
                    </Button>
                </Upload>
                <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={handleStart}
                    htmlType="button"
                >
                    {t('labels.upload')}
                </Button>
            </div>

            <div className={styles.line}>
                <Progress percent={progress} />
            </div>
        </div>
    );
};
