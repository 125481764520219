import React from 'react';
import {Divider, Table} from "antd";
import {useTranslate} from "@refinedev/core";
import {CheckCircleFilled, CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";

export default ({data}: any) => {
  const t = useTranslate()

  return <>
    <Table
        scroll={{x: true}}
        dataSource={data}
        rowKey={'code'}
    >
      <Table.Column
          dataIndex="type"
          title={t('labels.status')}
          render={(_, record: any) => {
            if (record.type === 'error') {
              return <CloseCircleTwoTone twoToneColor={'#eb2f96'} style={{fontSize: 27}}/>
            }

            return <CheckCircleTwoTone twoToneColor={'#52c41a'} style={{fontSize: 27}}/>
          }}
      />
      <Table.Column dataIndex="code" title={t('labels.code')}/>
      <Table.Column dataIndex={['rule', 'category']} title={t('labels.category')}/>
      <Table.Column dataIndex={['rule', 'criteria']} title={t('labels.criteria')}/>
      <Table.Column dataIndex={['rule', 'comment']} title={t('labels.comment')}/>
      <Table.Column
          dataIndex="details"
          title={t('labels.details')}
          render={(_, record: any) => Array(record.details).map((detail: any, key: number) => <p
              key={key}>{detail}</p>)}
      />
    </Table>
  </>
}
