import {Authenticated, Refine} from '@refinedev/core';
import {RefineKbar, RefineKbarProvider} from '@refinedev/kbar';

import {ErrorComponent, notificationProvider} from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import {dataProvider} from './dataProvider';
import {endpoint} from './config';
import {Login} from 'pages/login';
import {useTranslation} from 'react-i18next';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import {authProvider} from './authProvider';
import {ColorModeContextProvider} from './contexts/color-mode';
import {ActivityList, ActivityShow} from './pages/activities';
import {UploaderCreate} from './pages/uploader';
import {TusClientProvider} from 'use-tus';
import {InternalLayout} from './components/Layouts/InternalLayout';
import {DossierList, DossierShow, DossierCreate} from './pages/dossier';
import {EmployeeCreate, EmployeeList} from "./pages/employees";
import {ProductList, ProductShow} from "./pages/products";

function App() {
  const {t, i18n} = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(`data.${[key]}`, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const transout = (key: string) => i18nProvider.translate(key, {});

  return (
      <TusClientProvider>
        <BrowserRouter>
          <RefineKbarProvider>
            <ColorModeContextProvider>
              <Refine
                  dataProvider={dataProvider(endpoint)}
                  notificationProvider={notificationProvider}
                  routerProvider={routerBindings}
                  authProvider={authProvider}
                  i18nProvider={i18nProvider}
                  resources={[
                    {
                      name: "product",
                      list: "/product",
                      create: "/product/create",
                      meta: {
                        label: transout('labels.products'),
                      }
                    }, {
                      name: 'activity',
                      list: '/activity',
                      show: '/activity/show/:id',
                      meta: {
                        label: transout('labels.activity'),
                      },
                    }, {
                      name: 'dossier',
                      create: '/dossier/import',
                      meta: {
                        label: transout('labels.dossier'),
                      },
                    }, {
                      name: 'employee',
                      create: '/employee/create',
                      list: '/employee',
                      show: '/employee/show/:id',
                      meta: {
                        label: transout('labels.employees'),
                      },
                    }, {
                      name: 'uploader',
                      // list: '/uploader/create',
                      meta: {
                        label: transout('labels.file_uploader'),
                      },
                    }, {
                      name: "product.dossier",
                      show: '/product/:product_id/dossier/:id',
                    }]}
                  options={{
                    syncWithLocation: true,
                    warnWhenUnsavedChanges: true,
                  }}
              >
                <Routes>
                  <Route
                      element={
                        <InternalLayout
                            fallback={
                              <CatchAllNavigate to="/login"/>
                            }
                        />
                      }
                  >
                    <Route
                        index
                        element={
                          <NavigateToResource resource="products" />
                        }
                    />
                    <Route path="/product">
                      <Route
                          index
                          element={<ProductList/>}
                      />
                      <Route
                          path=":product_id/dossier"
                          element={<DossierList/>}
                      />
                      <Route
                          path=":product_id/dossier/:id"
                          element={<DossierShow/>}
                      />
                    </Route>
                    <Route path="/activity">
                      <Route
                          index
                          element={<ActivityList/>}
                      />
                      <Route
                          path="show/:id"
                          element={<ActivityShow/>}
                      />
                    </Route>
                    <Route>
                      <Route
                          path="/uploader/create"
                          element={<UploaderCreate/>}
                      />
                    </Route>
                    <Route
                        path="dossier/import"
                        element={<DossierCreate/>}
                    />
                    <Route path="/employee">
                      <Route
                          index
                          element={<EmployeeList/>}
                      />
                      <Route
                          path="create"
                          element={<EmployeeCreate/>}
                      />
                    </Route>
                  </Route>
                  <Route
                      element={
                        <Authenticated fallback={<Outlet/>}>
                          <NavigateToResource/>
                        </Authenticated>
                      }
                  >
                    <Route path="/login" element={<Login/>}/>
                  </Route>
                  <Route element={<InternalLayout/>}>
                    <Route
                        path="*"
                        element={<ErrorComponent/>}
                    />
                  </Route>
                </Routes>

                <RefineKbar/>
                <UnsavedChangesNotifier/>
              </Refine>
            </ColorModeContextProvider>
          </RefineKbarProvider>
        </BrowserRouter>
      </TusClientProvider>
  );
}

export default App;
