import {HttpError} from "@refinedev/core";
import axios from "axios";

import {endpoint} from "../config";

const axiosInstance = axios.create({
  baseURL: endpoint
});

axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // const customError: HttpError = {
      //   ...error,
      //   message: error.response?.data?.message,
      //   statusCode: error.response?.status,
      // };

      return Promise.reject(error);
    },
);

export {axiosInstance};