import React, {BaseSyntheticEvent, useEffect, useState} from 'react';
import {Button, Col, Drawer, Input, Row, Space, Spin, Tabs, Tag, Tree, Radio} from "antd";
import {FileOutlined, FolderOpenOutlined, LoadingOutlined} from "@ant-design/icons";
import {api} from "../../utils";
import _ from 'lodash';
import {useTranslate} from "@refinedev/core";

const {Compact} = Space

const flattenTree = (tree: any) => {
  function recurse(nodes: any, path: any): any {
    return _.map(nodes, function (node) {
      var newPath = _.union(path, [node.key]);

      return [
        _.assign({pathname: newPath.join('>'), level: path.length}, _.omit(node, 'children')),
        recurse(node.children, newPath)
      ];
    });
  }

  return _.flattenDeep(recurse(tree, []));
}
export default ({record}: any) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerTabs, setDrawerTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState('1');

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const t = useTranslate();
  const [treeSelector, setTreeSelector] = useState('sequence');
  const activeTree = record?.tree?.[treeSelector]
  const onCloseTab = (targetKey: any) => {
    const keys = drawerTabs.filter((item: any) => item.key !== targetKey);

    if (keys.length) {
      setActiveTab(keys[0].key)
    } else {
      setDrawerTabs([])
      setOpenDrawer(false)
    }

    setDrawerTabs(keys)
  }

  const handleSelectTreeView = (e: any) => {
    setTreeSelector(e.target.value)
  }

  useEffect(() => {
    expandAll()
  }, [treeSelector]);

  const handleClickNode = async (selected: any, {node: item}: any) => {
    try {
      if (item?.id && item?.operation !== 'delete') {
        const exist = drawerTabs.filter((i: any) => i.key === item.id).length > 0;
        let elements = drawerTabs

        if (!exist) {
          elements = [
            {
              key: item.id,
              label: item.title,
              children: <Row justify={'center'}><LoadingOutlined style={{fontSize: 72, marginTop: '25vh'}} spin/></Row>
            },
            ...drawerTabs,
          ];
          setDrawerTabs(elements)
        }
        setActiveTab(item.id)
        setOpenDrawer(true)

        if (!exist) {
          const {data} = await api.get(`/dossier/${record?.id}/${item.id}`, {
            method: 'GET',
            responseType: 'blob',
          })
          const fileURL = URL.createObjectURL(data)
          setDrawerTabs(
              elements.map((i: any) => {
                if (i.key === item.id) {
                  i.children = <div style={{height: '80vh'}}>
                    <embed
                        src={fileURL}
                        width={'100%'}
                        height={'100%'}
                    />
                  </div>
                }

                return i
              })
          )
        }
      } else {
        let keys: any = expandedKeys;

        if (item.children) {
          let childrenKeys = flattenTree(item.children).map((item: any) => item.key)
          keys = keys.filter((i: string) => !childrenKeys.includes(i))
        }

        setExpandedKeys(_.xor(keys, [item.key]))
      }
    } catch (e) {
      console.error(e);
    }
  }
  const onSearch = (e: BaseSyntheticEvent) => {
    const {value} = e.target;

    setSearchValue(value.toLowerCase())
    setExpandedKeys(
        _.flatten(
            value ?
                flattenTree(activeTree)
                    .filter((item: any) => (`${item.title} ${item.operation}`).toLowerCase().includes(value.toLowerCase()))
                    .map((item: any) => item.pathname.split('>'))
                : []
        )
    )

    if (value.trim().length === 0) {
      expandAll()
    }
  };
  const titleRender = (node: any) => {
    let operation: string = node.operation;
    const color = {
      'new': '#87d068',
      'replace': '#108ee9',
      'delete': '#f50'
    }[operation]

    if (treeSelector !== 'cumulative' && node.sequence !== record.sequence) {
      operation = ''
    }

    if (treeSelector === 'cumulative' && node.sequence === '0000') {
      operation = '';
    }

    const title = node.title
    const index = title.toLowerCase().indexOf(searchValue);
    const beforeStr = title.substring(0, index);
    const coincided = title.substring(index, index + searchValue.length);
    const afterStr = title.slice(index + searchValue.length);
    const formattedTitle =
        index > -1 ? (
            <span>
              {beforeStr}
              <span style={{background: '#87d068', fontWeight: 'bold'}}>{coincided}</span>
              {afterStr}
            </span>
        ) : (
            <span>{title}</span>
        );

    return <span>
      {operation && (
          <>&nbsp;<Tag color={color} bordered>{operation.toUpperCase()}</Tag></>
      )}
      {formattedTitle}
      {(treeSelector === 'cumulative' && node.sequence) && <b>&nbsp;{node.sequence}</b>}
    </span>
  }
  const onExpand = (expandedKeys: any, {expanded: isOpen, node}: any) => {
    if (!isOpen) {
      let children = flattenTree(node.children).map((item: any) => item.key).concat(node.key)

      return setExpandedKeys(expandedKeys.filter((i: string) => !children.includes(i)))
    }

    setExpandedKeys(_.union(expandedKeys, expandedKeys))
  }

  const expandAll = () => {
    setSearchValue('')
    setExpandedKeys(flattenTree(activeTree).map((item: any) => item.key))
  }
  const toggleAll = () => {
    setSearchValue('')
    setExpandedKeys(expandedKeys.length ? [] : flattenTree(activeTree).map((item: any) => item.key))
  }
  const renderIcon = ({data: {isLeaf}}: any) => isLeaf ? <FileOutlined/> : <FolderOpenOutlined/>

  return <>
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Radio.Group
            onChange={handleSelectTreeView}
            value={treeSelector}
        >
          <Radio value={'sequence'}>Sequence</Radio>
          <Radio value={'cumulative'}>Cumulative</Radio>
          <Radio value={'current'}>Current</Radio>
        </Radio.Group>
      </Col>
      <Col span={24}>
        <Compact
            style={{
              width: '100%',
              marginBottom: 8,
            }}
        >
          <Input
              placeholder={
                  `${t('labels.search')}...` +
                  `${t('labels.file').toLowerCase()} | ` +
                  `${t('labels.folder').toLowerCase()} | ` +
                  `${t('labels.operation').toLowerCase()}`
              }
              value={searchValue}
              onChange={onSearch}
          />
          <Button onClick={toggleAll} type="primary">
            {t('labels.expand_all')}/{t('labels.collapse_all')}
          </Button>
        </Compact>
      </Col>
    </Row>

    <Tree
        showLine
        icon={renderIcon}
        showIcon={true}
        autoExpandParent={false}
        expandedKeys={expandedKeys}
        onSelect={handleClickNode}
        onExpand={onExpand}
        treeData={activeTree}
        titleRender={titleRender}
    />

    <Drawer
        width={'80%'}
        title={t('labels.preview')}
        placement="right"
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
    >
      <Tabs
          hideAdd
          activeKey={activeTab}
          type="editable-card"
          items={drawerTabs}
          onChange={(key) => setActiveTab(key)}
          defaultActiveKey={'1'}
          onEdit={onCloseTab}
      />
    </Drawer>
  </>
}
