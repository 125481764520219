import React from 'react';
import {
    IResourceComponentsProps,
    BaseRecord,
    useTranslate,
} from '@refinedev/core';
import { useTable, List, ShowButton, TagField } from '@refinedev/antd';
import { Table, Space, Button } from 'antd';
import { CustomBreadcrumbs } from '../../components/CustomBreadcrumbs';

export const ActivityList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    const t = useTranslate();

    const columns = [
        {
            title: t('labels.log_name'),
            dataIndex: 'log_name',
            width: 1,
            render: (text: string, record: any) => {
                let color: string = 'default';

                if (text === 'AUTH') color = 'green';
                if (text === 'AUTH_FAILED') color = '#f50';
                if (text === 'REQUEST') color = '#2db7f5';
                if (text === 'MODEL') color = 'purple';

                return (
                    <Space direction={'vertical'}>
                        <TagField
                            key={'text'}
                            bordered={false}
                            color={color}
                            value={text}
                        />
                    </Space>
                );
            },
        },
        {
            title: t('labels.http_code'),
            dataIndex: 'status_code',
            width: 150,
            render: (code: number, record: any) => {
                const { method } = record;
                let color = '#87d068';
                let methodColor = '#2db7f5';

                if (code >= 300) color = '#f50';
                if (method === 'POST') methodColor = '#7cb305';
                if (method === 'GET') methodColor = '#2db7f5';
                if (method === 'PATCH') methodColor = '#08979c';

                return (
                    <Space direction={'vertical'}>
                        {method && (
                            <TagField
                                key={'method'}
                                bordered={false}
                                color={methodColor}
                                value={method}
                            />
                        )}
                        {code && (
                            <TagField
                                key={'text'}
                                bordered={false}
                                color={color}
                                value={code}
                            />
                        )}
                    </Space>
                );
            },
        },
        {
            title: t('labels.description'),
            dataIndex: 'description',
        },
        {
            title: t('labels.user'),
            dataIndex: ['user', 'name'],
        },
        {
            title: t('labels.created_at'),
            dataIndex: 'created_at',
            width: 150,
        },
        {
            width: 1,
            render: (text: string, record: any) => {
                return (
                    <ShowButton
                        recordItemId={record.id}
                        size={'large'}
                        hideText
                    />
                );
            },
        },
    ];

    return (
        <List breadcrumb={<CustomBreadcrumbs />}>
            <Table {...tableProps} rowKey="id" columns={columns}>
                <Table.Column dataIndex="log_name" title="Log Name" />
                <Table.Column dataIndex="description" title="Description" />
                <Table.Column dataIndex="created_at" title="Created At" />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
