import { FC } from 'react';
import { Authenticated } from '@refinedev/core';
import { ThemedLayoutV2 } from '@refinedev/antd';
import { Header } from '../../header';
import CustomSider from '../../CustomSider';
import { Outlet } from 'react-router-dom';

interface IInternalLayoutProps {
    fallback?: JSX.Element;
}

export const InternalLayout: FC<IInternalLayoutProps> = ({ fallback }) => {
    return (
        <Authenticated fallback={fallback}>
            <ThemedLayoutV2 Header={Header} Sider={CustomSider}>
                <Outlet />
            </ThemedLayoutV2>
        </Authenticated>
    );
};
