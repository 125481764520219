import React from 'react';
import { Link } from 'react-router-dom';
import { useBreadcrumb, useLink, useTranslate } from '@refinedev/core';

import styles from './CustomBreadcrumbs.module.scss';

function isCyrillic(str: string) {
    let re = /[а-яА-ЯЁё]/;
    return re.test(str);
}

export const CustomBreadcrumbs = () => {
    const { breadcrumbs } = useBreadcrumb();
    const t = useTranslate();
    const Link = useLink();

    if (breadcrumbs.length === 1) {
        return null;
    }

    return (
        <ul className={styles.BreadcrumbsWrapper}>
            {breadcrumbs?.map(({ label, href, icon }) => {
                let output = isCyrillic(label)
                    ? label
                    : t(`labels.${label.toLowerCase()}`);

                return (
                    <li key={output} className={styles.BreadcrumbItem}>
                        {icon}
                        {href ? (
                            <Link to={href} className={styles.BreadcrumbLink}>
                                {output}
                            </Link>
                        ) : (
                            output
                        )}
                    </li>
                );
            })}
        </ul>
    );
};
